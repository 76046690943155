import isEmpty from 'lodash/isEmpty';
import Vue from 'vue';
import { Client } from '@/services';
import vuetify from '@/plugins/vuetify';
import { USER_SUCCESS, USER_ROLES, USER_REQUEST } from '@/store/actions/user';
import { AlertType, ResponseType } from '@/constants';
import { User as UserModel } from '@/models/orm/Hierarchy';
import { Services } from '../../../store/actions/api';
import { jsonToFormData } from '../../../utils/http-common';
import { captureException } from '@sentry/vue';

export default {
  namespaced: true,
  state: {
    loading: false,
    transaction: {},
  },
  getters: {
    isDataReady(state) {
      return !isEmpty(state.transaction);
    },
    transaction(state) {
      return state.transaction;
    },
  },
  mutations: {
    loading(state, loading) {
      state.loading = loading;
    },
    transactionSuccess(state, transaction) {
      Vue.set(state, 'transaction', transaction);
    },
  },
  actions: {
    async initialize() {
      console.info('User profile initializing...');
      console.info('User profile initialized.');
    },

    async updateProfile({ dispatch, rootGetters}, { firstName, lastName, displayName, genderID }) {
      let user = rootGetters.currentProfile;
      try {
        const params = {
          service: Services.FlightScopeUser.key,
          method: Services.FlightScopeUser.methods.ConfigureUser.key,
        };

        const timestamp = Math.floor(Date.now() / 1000);

        const payload = {
          TS: timestamp,
          GenderID: genderID,
          DisplayName: displayName,
          FirstName: firstName,
          LastName: lastName,
          IsPublic: user.IsPublic,
          Handedness: user.Handedness,
        };

        const formDataPayload = jsonToFormData(payload);

        const response = await Client({
          method: Services.FlightScopeUser.methods.ConfigureUser.type,
          params,
          data: formDataPayload,
          localError: true,
        });

        const { User, Roles } = response.data;

        const { Role } = Roles ?? {};

        if (Array.isArray(Role)) {
          User.roles = Role;
          dispatch(USER_ROLES, User.roles, { root: true });
        } else if (Role) {
          User.roles = [Role];
          dispatch(USER_ROLES, User.roles, { root: true });
        }

        UserModel.update({ where: User.ID, data: [User] });
        dispatch(USER_SUCCESS, User, { root: true });

        return { type: AlertType.success };
      } catch (error) {
        captureException(error);
        Vue.$log.debug('There was errors during updating user.', error);
        return {
          type: AlertType.error,
          message: vuetify.framework.lang.t('$vuetify.alerts.request_error'),
        };
      }
    },

    async updateTos({ dispatch, rootGetters}, TosAcceptedOn ) {
      let user = rootGetters.currentProfile;

      try {
        const params = {
          service: Services.FlightScopeUser.key,
          method: Services.FlightScopeUser.methods.ConfigureUser.key,
        };

        const timestamp = Math.floor(Date.now() / 1000);

        const payload = {
          TS: timestamp,
          GenderID: user.GenderID,
          DisplayName: user.DisplayName,
          FirstName: user.Firstname,
          LastName: user.Lastname,
          IsPublic: user.IsPublic,
          // NOTE - it looks like parameters json are appended only
          // ParametersJSON: Object.assign(user.Parameters, {
          //   TosAcceptedOn
          // }),
          ParametersJSON: {
            TosAcceptedOn
          },
          Handedness: user.Handedness,
        };

        const formDataPayload = jsonToFormData(payload);

        const response = await Client({
          method: Services.FlightScopeUser.methods.ConfigureUser.type,
          params,
          data: formDataPayload,
          localError: true,
        });

        const { User, Roles } = response.data;

        const { Role } = Roles ?? {};

        if (Array.isArray(Role)) {
          User.roles = Role;
          dispatch(USER_ROLES, User.roles, { root: true });
        } else if (Role) {
          User.roles = [Role];
          dispatch(USER_ROLES, User.roles, { root: true });
        }

        UserModel.update({ where: User.ID, data: [User] });
        dispatch(USER_SUCCESS, User, { root: true });

        return { type: AlertType.success };
      } catch (error) {
        captureException(error);
        Vue.$log.debug('There was errors during updating user.', error);
        return {
          type: AlertType.error,
          message: vuetify.framework.lang.t('$vuetify.alerts.request_error'),
        };
      }
    },

    async getTransaction({ dispatch, commit }) {
      commit('loading', true);
      try {
        const params = {
          service: Services.Subscription.key,
          method: Services.Subscription.methods.GetTransaction.key,
          gateway: 'stripe',
        };

        const response = await Client({
          method: Services.Subscription.methods.GetTransaction.type,
          params,
        });
        commit('transactionSuccess', response.data.response);

        return { type: ResponseType.SUCCESS };
      } catch (error) {
        captureException(error);
        return {
          type: AlertType.error,
          message: vuetify.framework.lang.t('$vuetify.alerts.request_error'),
        };
      } finally {
        commit('loading', false);
      }
    },
    async cancelMembership({ dispatch, commit }) {
      commit('loading', true);

      try {
        const params = {
          service: Services.Subscription.key,
          method: Services.Subscription.methods.CancelMembership.key,
          gateway: 'stripe',
        };

        const response = await Client({
          method: Services.Subscription.methods.CancelMembership.type,
          params,
          localError: true,
        });

        // Get new transaction data after cancelling subscription
        await dispatch('getTransaction');

        return { type: ResponseType.SUCCESS };
      } catch (error) {
        captureException(error);
        return {
          type: ResponseType.ERROR,
        };
      } finally {
        commit('loading', false);
      }
    },

    async updateMembership({ dispatch, commit }, recurringPlan) {
      commit('loading', true);

      await dispatch(USER_REQUEST, null, { root: true });

      try {
        const params = {
          service: Services.Subscription.key,
          method: Services.Subscription.methods.UpdateMembership.key,
          gateway: 'stripe',
          recurringPlan,
        };

        await Client({
          method: Services.Subscription.methods.UpdateMembership.type,
          params,
          localError: true,
        });

        // TODO: Loop?
        // Get new transaction data after cancelling subscription
        await dispatch('getTransaction');
        await dispatch(USER_REQUEST, null, { root: true });

        return { type: ResponseType.SUCCESS };
      } catch (error) {
        captureException(error);
        return {
          type: ResponseType.ERROR,
        };
      } finally {
        commit('loading', false);
      }
    },
  },
};
