import Store from '../../../store';

const Header = () => import('@/components/core/CoreHeader.vue');
const Drawer = () => import('@/components/core/CoreDrawer.vue');
const UserProfileLayout = () => import('@/modules/user-profile/components/UserProfileLayout.vue');
const MyProfile = () => import('@/modules/user-profile/views/MyProfile.vue');
const ManageMembership = () => import('@/modules/user-profile/components/ManageMembership.vue');

export default [
  {
    path: '/profile',
    name: 'profile',
    meta: {
      requiresAuth: true,
    },
    redirect: { name: 'profile.my' },
    components: {
      header: Header,
      drawer: Drawer,
      default: UserProfileLayout,
    },
    children: [
      {
        path: 'my',
        name: 'profile.my',
        component: MyProfile,
        meta: {
          title: 'My Profile',
        },
      },
      {
        path: 'manage-membership',
        name: 'profile.manageMembership',
        component: ManageMembership,
        meta: {
          title: 'Manage Membership',
        },
        beforeEnter: (to, from, next) => {
          if (Store.hasModule('servicePlan')) {
            next();
          } else {
            next({ name: 'profile.my' });
          }
        },
      },
    ],
  },
];
